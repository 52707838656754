import '@/assets/scripts/segment.js';
import { liveMeeting, isExtension, getPlan, getEra } from '@/components/Utils';
import Vue from 'vue';

window.meetric = {
  track: function (name, props = {}) {
    let p = getPlan();
    let era = getEra();

    console.log(p, era);

    if (p >= 2 || era == 0) {
      console.log("tracking", name);

      window.analytics.track(name, {
        ...props,
        isAuth: Vue.gAuth.isAuthorized,
        livemeeting: liveMeeting(),
        isExtension: isExtension(),
      });
    }
  },
  page: function (name, props = {}) {
    let p = getPlan();
    let era = getEra();
    console.log(p, era);
    if (p >= 2 || era == 0) {
      console.log("paging", name);
      window.analytics.page(name, {
        ...props,
        isAuth: Vue.gAuth.isAuthorized,
        isExtension: isExtension(),
      });
    }
  },
  identify: function (name, props = {}) {
    let p = getPlan();
    let era = getEra();
    console.log(p, era);
    if (p >= 2 || era == 0) {
      console.log("identifiying", name);

      window.analytics.identify(name, {
        ...props,
        LOGGEDIN: 'swanson',
      });
    }
  },
};
