<template>
  <input
    class="rounded w-full focus:outline-none focus:border-transparent bg-transparent"
    type="text"
    :value="value"
    :id="id"
    :placeholder="placeholder"
    :title="title"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  name: 'MInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
