<template>
  <div v-if="getOS == 'mac' || getOS == 'ios'" class="text-md">
    {{ icon }}
  </div>
  <div v-else>
    <img
      class="w-4"
      :src="require(`../assets/emojis/${toHex(icon)}.svg`)"
    />
  </div>
</template>

<script>
export default {
  name: "EmojiSwitch",
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    getOS: function() {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = "mac";
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "ios";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "windows";
      } else if (/Android/.test(userAgent)) {
        os = "android";
      } else if (!os && /Linux/.test(platform)) {
        os = "linux";
      }
      return os;
    },
  },
  methods: {
    toHex(emoji) {
      var emojiCode = Array.from(emoji)
        .map((v) => v.codePointAt(0).toString(16))
        .map((hex) => "0000".substring(0, 4 - hex.length) + hex);
      return emojiCode.join("-").replace("-fe0f","");
    },
  },
};
</script>
