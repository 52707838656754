<template>
  <div
    class="text-grey2 cursor-pointer search-result border-grey6 border-b py-4 px-6"
    :class="{
      'is-selected': highlight,
    }"
  >
    <div
      class="title flex flex-col lg:flex-row min-w-0 mr-0"
      data-recording-sensitive
    >
      <div class="flex title-content">
        <h3 class="font-normal text-xl truncate">
          <span v-html="title"></span>
        </h3>
      </div>
      <div class="flex mt-0 lg:mt-1" v-if="startDate || isPrivate">
        <MCIDate v-if="startDate" :startDateTime="startDate"></MCIDate>
        <div v-if="isPrivate" class="flex ml-3 flex-col justify-center">
          <div
            class="text-sm px-3 bg-white dark:bg-base5 text-base3 rounded-full border border-grey6"
            style="padding-top: 0.1rem; padding-bottom: 0.1rem"
          >
            Private
          </div>
        </div>
      </div>
    </div>
    <div class="font-base content mt-2" v-if="content">
      <span v-html="content"></span>
    </div>
  </div>
  <!-- </router-link> -->
</template>

<script>
import MCIDate from '@/components/UI/MeetingCardIcons/Date';

export default {
  name: 'SearchItem',
  components: {
    MCIDate,
  },
  props: {
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },

    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    isPrivate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    startDate() {
      return this.item?._source?.start || '';
    },
    title() {
      if (this.item?.highlight?.['title.ngrams']?.[0]) {
        return this.item.highlight['title.ngrams'][0];
      } else if (this.item?._source?.title) {
        return this.item._source.title;
      }

      return '(No title)';
    },
    content() {
      if (this.item?.highlight?.['doc.ngrams']?.[0]) {
        return this.item.highlight['doc.ngrams'][0].trim() + '&#8230;';
      }

      return '';
    },
  },
  mounted() {},
};
</script>
<style scoped>
.title-content {
  max-width: 100%;
}

@screen lg {
  .title-content {
    max-width: 75%;
  }
}
.is-selected {
  @apply bg-grey6;
}

.search-result .content >>> em,
.search-result .title >>> em {
  all: unset;
  @apply text-grey1 font-medium;
}

/* Border around next meeting in meeting list */
/* .event-highlight.event-panel {
  @apply border-2 border-base2 border-opacity-50 border-l-0 lg:border-l-2 border-r-0 lg:border-r-2;
} */
</style>
