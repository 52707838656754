const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

const preferences = {

  setTheme: function(mode = 'system') {
    if (mode === 'dark') {
      document.querySelector('html').classList.add('dark');
      removeListeners(mediaQuery);
    } else if (mode === 'light') {
      document.querySelector('html').classList.remove('dark');
      removeListeners(mediaQuery);
    } else {
      // Set system settings
      if (mediaQuery.matches) {
        document.querySelector('html').classList.add('dark');
      } else {
        document.querySelector('html').classList.remove('dark');
      }

      // Listen to system changes
      try {
        // Chrome and Firefox
        mediaQuery.addEventListener('change', toggleDarkMode);
      } catch (err) {
        try {
          // Old Safari
          mediaQuery.addListener(toggleDarkMode);
        } catch (err2) {
          console.error("Can't listen to dark mode");
        }
      }
    }
  },

  init: function(preferences) {
    this.setTheme(preferences.dark_mode);
  },
};

function removeListeners(query) {
  try {
    // Chrome and Firefox
    query.removeEventListener('change', toggleDarkMode);
  } catch (err) {
    try {
      // Old Safari
      query.removeListener(toggleDarkMode);
    } catch (err2) {
      console.error("Can't unlisten to dark mode");
    }
  }
}
function toggleDarkMode(e) {
  if (e.matches) {
    document.querySelector('html').classList.add('dark');
  } else {
    document.querySelector('html').classList.remove('dark');
  }
}

export default preferences;
