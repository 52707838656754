<template>
  <div style="z-index: 100" class="fixed">
    <!-- Background -->
    <div
      class="screen-overlay fixed inset-0 bg-grey1 bg-opacity-25 flex z-20 lg:pl-12 flex justify-center"
    >
      <div
        class="bg-white dark:bg-base5 rounded-md z-20 shadow m-auto flex flex-col text-grey1 p-4"
      >
        <div class="flex text-center">
          You were signed out of your account. <br />Please press ‘Reload’ to
          sign in again.
        </div>
        <div class="flex justify-center mt-4">
          <m-button
            button-style="btn-primary"
            icon="🔃"
            class=""
            @click="refresh"
          >
            Reload
          </m-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MButton from '@/components/UI/MButton';

export default {
  components: {
    MButton,
  },
  data() {
    return {};
  },
  mounted() {
    console.log('blocked');
    this.$store.commit('setSessionBlocked', true);

    window.meetric.track('Screen blocker', {});
  },
  methods: {
    refresh() {
      location.reload(true);
    },
  },
};
</script>

<style scoped>
.dark .screen-overlay {
  background-color: rgba(20, 20, 20, 0.65);
}
</style>
