import Vue from 'vue';
import moment from 'moment';

async function getCalendarEvents(calendarIds, searchParams) {
  let urlParams = {
    maxResults: 250,
    orderBy: 'startTime',
    singleEvents: true,
    timeMax: moment(new Date()).add(14, 'days').endOf('day').format(),
    timeMin: moment(new Date()).subtract(14, 'days').startOf('day').format(),
  };

  if (searchParams) {
    // use default and any user defined
    urlParams = { ...urlParams, ...searchParams };
  }

  return Promise.all(
    calendarIds.map(async (cid) => {
      const url = `https://www.googleapis.com/calendar/v3/calendars/${cid}/events`;

      const fullURL = buildURL(url, urlParams);
      const params = await buildParams('GET');

      return sendGoogleRequest(fullURL, params);
    })
  ).then((responses) => {
    let events = [];
    let items = null;
    responses.forEach((r, index) => {
      if (r?.items) {
        items = r.items.map((i) => ({ event: i, cid: calendarIds[index] }));
        events = [...events, ...items];
      }
    });
    return events;
  });
}

/* Get all calendar events */
export async function getAllCalendarEvents(
  calendarId = 'primary',
  searchParams = null
) {
  if (Array.isArray(calendarId)) {
    return getCalendarEvents(calendarId, searchParams);
  } else {
    return getCalendarEvents([calendarId], searchParams);
  }
}

async function getCalendarEvent(calendarIds, eventId) {
  return Promise.all(
    calendarIds.map(async (cid) => {
      const url = `https://www.googleapis.com/calendar/v3/calendars/${cid}/events/${eventId}`;

      const fullURL = buildURL(url);
      const params = await buildParams('GET');

      return sendGoogleRequest(fullURL, params);
    })
  ).then((responses) => {
    // return first valid
    for (let i = 0; i < responses.length; i++) {
      // ignore cancelled events as they don't have summary, start/end dates etc
      // if (responses[i] && responses[i]?.status != 'cancelled') {
      if (responses[i]) {
        return { event: responses[i], cid: calendarIds[i] };
      }
    }

    return null;
  });
}

/* Get specific calendar event by ID */
export async function getCalendarEventById(
  calendarId = 'primary',
  eventId = ''
) {
  if (Array.isArray(calendarId)) {
    return getCalendarEvent(calendarId, eventId);
  } else {
    return getCalendarEvent([calendarId], eventId);
  }
}

/* Patch specific meeting */
export async function patchMeeting(
  calendarId = 'primary',
  meetingId = '',
  options = {}
) {
  const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${meetingId}`;

  const fullURL = buildURL(url);
  const params = await buildParams('PATCH');
  params.body = JSON.stringify(options);

  return sendGoogleRequest(fullURL, params);
}
/* Get next recurring event for recurringID */
export async function getNextRecurringEvent(
  calendarId = 'primary',
  recuringEventId = '',
  timeMin = ''
) {
  const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${recuringEventId}/instances`;
  let urlParams = {
    maxResults: 1,
    timeMin: timeMin,
  };

  const fullURL = buildURL(url, urlParams);
  const params = await buildParams('GET');
  return sendGoogleRequest(fullURL, params);
}

/* Get list of calendars */
export async function getCalendars() {
  const url = 'https://www.googleapis.com/calendar/v3/users/me/calendarList';
  const fullURL = buildURL(url);
  const params = await buildParams('GET');
  return sendGoogleRequest(fullURL, params);
}

// join the parameters directly to url as query parameters
function buildURL(url, params = {}) {
  let fullURL = new URL(url);
  fullURL.search = new URLSearchParams(params);
  return fullURL;
}

async function buildParams(method) {
  return Vue.gAuth.GoogleAuth.then((r) => {
    const accessToken = r.currentUser.get().getAuthResponse().access_token;
    const params = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    };
    return params;
  });
}

async function sendGoogleRequest(path, params) {
  return fetch(path, params)
    .then((response) => {
      if (response.status == 200) {
        return response
          .clone()
          .json()
          .catch(() => {
            console.log('Error with json');
            console.log(response.text());
          });
      } else if (response.status == 404) {
        return null; // we should handle 404 better
      } else {
        return null;
      }
    })
    .catch(() => console.log('Error fetching'));
}
