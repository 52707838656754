import Vue from 'vue';

export const tokenType = Object.freeze({ ID: 1, ACCESS: 2 });

export async function sendRequestBase(url, params) {
  return fetch(url, params).then((response) => {
    if (response.status == 200) {
      const jsonResponse = response.clone().json();
      return jsonResponse;
    } else {
      if (response.error && response.error.code) {
        return response.clone().json();
      } else {
        // response from authorizer is just code
        return { error: { code: response.status } };
      }
    }
  });
}

export async function getParamsBase(body, verb, tokenTypeToAdd) {
  let params = {};
  params.method = verb;
  params.headers = {
    'Content-Type': 'application/json',
  };
  if (body) {
    params.body = JSON.stringify(body);
  }

  if (Vue.gAuth?.isAuthorized) {
    let idToken = Vue.gAuth.GoogleAuth.currentUser.get().getAuthResponse()
      .id_token;
    let accessToken = Vue.gAuth.GoogleAuth.currentUser.get().getAuthResponse()
      .access_token;
    const exp = JSON.parse(atob(idToken.split('.')[1])).exp * 1000;
    if (exp < Date.now()) {
      var result;
      try {
        result = await Vue.gAuth.GoogleAuth.currentUser
          .get()
          .reloadAuthResponse();
        idToken = result.id_token;
        accessToken = result.access_token;
      } catch (e) {
        console.error('Unable to reload authorization tokens');
      }
    }

    params.headers.Authorization =
      'Bearer ' + (tokenTypeToAdd == tokenType.ACCESS ? accessToken : idToken);
  } else {
    // we need Auth header even without token othervise Authorizer doesn't run
    params.headers.Authorization = 'Bearer ';
  }
  return params;
}
