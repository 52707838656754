<template>
  <button
    @click="onClick"
    class="focus:outline-none"
    :style="computedStyle"
    :class="computedClass.button"
    :disabled="disabled || pending"
  >
    <div v-if="icon && !pending" class="mr-3">
      <!-- @slot Optional icon -->
      <emoji-switch :icon="icon"></emoji-switch>
    </div>
    <div v-if="pending" class="mr-3">
      <m-spinner class="w-4" :class="computedClass.loader" />
    </div>
    <slot name="default"></slot>
  </button>
</template>

<script>
import EmojiSwitch from '@/components/EmojiSwitch';
import MSpinner from './MSpinner.vue';
export default {
  name: 'MButton',
  components: {
    EmojiSwitch,
    MSpinner,
  },
  props: {
    /**
     * The style of the button
     * @values btn-primary, btn-secondary, btn-tertiary, btn-quaternary
     */
    buttonStyle: {
      type: String,
      required: false,
      default: 'btn-primary',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    segmentName: {
      type: String,
      required: false,
    },
    segmentProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    pending: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit('click');

      if (this.segmentName) {
        const props = {
          ...this.segmentProps,
          source: this.$parent.$parent.$vnode.tag,
          type: 'Button click',
        };
        window.meetric.track(this.segmentName, props);
      }
    },
  },
  computed: {
    computedClass() {
      switch (this.buttonStyle) {
        case 'btn-primary':
          return {
            button: `
            font-sm font-medium rounded-md inline-flex items-center outline-none whitespace-nowrap
            bg-base2 text-white px-5 shadow-md h-9 lg:py-2
            hover:shadow-lg
            `,
            loader: `text-white`,
          };
        case 'btn-secondary':
          return {
            button: `
            font-sm bg-base3 text-white px-5 shadow-md
            hover:shadow-lg
            `,
            loader: `text-white`,
          };
        case 'btn-tertiary':
          return {
            button: `
            font-medium rounded-md inline-flex items-center outline-none whitespace-nowrap
            bg-grey6 text-sm text-grey2 h-8 border border-solid px-3 border-grey5 border-opacity-25
            hover:border hover:border-solid hover:border-grey4 hover:border-opacity-25 hover:shadow-sm
            `,
            loader: `text-grey2`,
          };
        case 'btn-quaternary':
          return {
            button: `
            rounded-full bg-grey6 border border-solid border-grey5 border-opacity-25
            text-2xl text-grey2 leading-3 items-center inline-block text-center
            hover:border hover:border-solid hover:border-grey4 hover:border-opacity-25 hover:shadow-sm
            `,
            loader: `text-grey2`,
          };
        default:
          return '';
      }
    },
    computedStyle() {
      switch (this.buttonStyle) {
        case 'btn-quaternary':
          return 'height: 1.40em; width: 1.40em';
        default:
          return '';
      }
    },
    hasIcon() {
      return !!this.$slots.icon;
    },
  },
};
</script>
<style scoped>
button:disabled {
  @apply cursor-default;
}
</style>
>
