export async function segmentTrackRaw(options) {
  const defaultOpt = {
    anonymousId: uuidv4(),
    event: 'Raw event',
  };

  const body = { ...defaultOpt, ...options };

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa(process.env.VUE_APP_SEGMENT_API_KEY + ':'),
    },
    body: JSON.stringify(body),
  };

  return fetch('https://api.segment.io/v1/track', params).then(response => {
    return response.json();
  });
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
