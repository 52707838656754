<template>
  <a
    :href="href"
    target="_blank"
    :class="
      nolinkstyle
        ? ''
        : 'underline cursor-pointer text-grey1 opacity-75 hover:opacity-100'
    "
    rel="nofollow"
    @click="onClick"
    :title="name"
    ><slot></slot
  ></a>
</template>

<script>
export default {
  name: 'ALink',
  components: {},
  data() {
    return {};
  },
  props: {
    href: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    segmentName: {
      type: String,
      required: false,
    },
    nolinkstyle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClick() {
      if (this.segmentName) {
        const props = {
          source: this.$parent.$parent.$vnode.tag,
        };
        window.meetric.track(this.segmentName, props);
      }
    },
  },
};
</script>

<style scoped></style>
