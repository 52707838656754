<template>
  <div
    @click="$emit('click')"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    class="flex lg:ml-3 items-center mt-1 lg:mt-0 mb-1 lg:mb-0"
    style="min-width: fit-content"
  >
    <!--<div class="meeting-icons">
      <svg
        width="18"
        height="18"
        viewBox="-0.5 0 17 21"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Symbols"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Icon/Calendar/Line"
            transform="translate(-3.000000, -2.000000)"
            fill="#FFFFFF"
          >
            <path
              d="M17.9999999,4 L16.9999999,4 L16.9999999,3 L16.9999999,3 C16.9999999,2.447715 16.552285,2 15.9999999,2 C15.447715,2 14.9999999,2.447715 14.9999999,3 L14.9999999,4 L8.99999987,4 L8.99999987,3 L8.99999987,3 C8.99999987,2.447715 8.552285,2 7.99999987,2 C7.447715,2 6.99999987,2.447715 6.99999987,3 L6.99999987,4 L5.99999987,4 L5.99999987,4 C4.34314987,4 2.99999987,5.34315 2.99999987,7 C2.99999987,7 2.99999987,7 2.99999987,7 L2.99999987,19 L2.99999987,19 C2.99999987,20.6568495 4.34314987,22 5.99999987,22 L17.9999999,22 L17.9999999,22 C19.6568497,22 20.9999999,20.6568495 20.9999999,19 L20.9999999,7 L20.9999999,7 C20.9999999,5.34314955 19.6568497,4 17.9999999,4 L17.9999999,4 Z M5.99999996,6 L6.99999996,6 L6.99999996,7 L6.99999996,7 C6.99999996,7.55228515 7.447715,8 7.99999996,8 C8.552285,8 8.99999996,7.55228515 8.99999996,7 L8.99999996,6 L15,6 L15,7 L15,7 C15,7.5522853 15.447715,8 16,8 C16.552285,8 17,7.5522853 17,7 L17,6 L18,6 L18,6 C18.552285,6 19,6.4477153 19,7 L19,11 L4.99999996,11 L4.99999996,7 L4.99999996,7 C4.99999996,6.44771545 5.44771496,6 5.99999996,6 L5.99999996,6 Z M18,20 L5.99999996,20 L5.99999996,20 C5.44771496,20 4.99999996,19.552285 4.99999996,19 C4.99999996,19 4.99999996,19 4.99999996,19 L4.99999996,13 L19,13 L19,19 L19,19 C19,19.552285 18.552285,20 18,20 L18,20 Z"
              id="Fill"
            ></path>
          </g>
        </g>
      </svg>
    </div>
    -->
    <div>
      <!--
      <transition name="fade" mode="out-in">
        <p
          key="1"
          v-if="!hover"
          class="ml-1 text-grey3"
          style="margin-top:-0.05rem"
        >
          {{ dateFormattedShort }}
        </p>
        <p v-else key="2" class="ml-1 text-grey3" style="margin-top:-0.05rem">
          {{ dateFormattedLong }}
        </p>
      </transition>-->
      <p key="2" class="text-grey3">
        {{
          timeOnly
            ? dateFormattedLong + ', ' + dateFormattedTime
            : dateFormattedLong
        }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'MCI-Date',
  props: {
    startDateTime: {
      type: String,
      required: true,
    },
    endDateTime: {
      type: String,
      required: false,
    },
    timeOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    dateFormattedTime: function () {
      let formatted = moment(this.startDateTime).format('hh:mma');
      if (this.endDateTime) {
        formatted = formatted + moment(this.endDateTime).format(' - hh:mma');
      }
      return formatted;
    },
    dateFormattedShort: function () {
      return moment(this.startDateTime).format('D MMM');
    },
    dateFormattedLong: function () {
      // return moment(this.startDateTime).format('ddd D MMM');
      let dateAbr = '';

      if (
        moment(this.startDateTime).format('YYYY-MM-DD') ===
        moment().format('YYYY-MM-DD')
      )
        dateAbr = 'Today';
      else if (
        moment(this.startDateTime).format('YYYY-MM-DD') ===
        moment().subtract(1, 'days').format('YYYY-MM-DD')
      )
        dateAbr = 'Yesterday';
      else if (
        moment(this.startDateTime).format('YYYY-MM-DD') ===
        moment().add(1, 'days').format('YYYY-MM-DD')
      )
        dateAbr = 'Tomorrow';
      else dateAbr = moment(this.startDateTime).format('ddd, MMM D');

      return dateAbr;
    },
  },
  methods: {},
};
</script>

<style scoped>
.meeting-icons {
  @apply ml-4;
  height: 1.35rem;
  width: 1.35rem;
}

.meeting-icons path {
  @apply fill-current text-grey3;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
